/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
window.BaseUrl = process.env.MIX_APP_URL

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// const register = Vue.component('register', require('./components/register.vue').default);

const modalDeleteEvent = Vue.component('modal-delete-event', () =>
    import (
        /* webpackChunkName: "modal-delete-event" */
        './components/account/modal-delete-event.vue'
    ));

const createGroupConv = Vue.component('create-group-conv', () =>
    import (
        /* webpackChunkName: "create-group-conv" */
        './components/conversations/group/create-group-conv.vue'
    ));

const accountInfo = Vue.component('account-info', () =>
    import (
        /* webpackChunkName: "account-info" */
        './components/account/updateProfil/account-info.vue'
    ));

const personnalData = Vue.component('personnal-data', () =>
    import (
        /* webpackChunkName: "personnal-data" */
        './components/account/updateProfil/personnal-data.vue'
    ));

const feed = Vue.component('feed', () =>
    import (
        /* webpackChunkName: "feed" */
        './components/account/updateProfil/feed.vue'
    ));

const other = Vue.component('other', () =>
    import (
        /* webpackChunkName: "other" */
        './components/account/updateProfil/other.vue'
    ));


const contactus = Vue.component('contact-us', () =>
    import (
        /* webpackChunkName: "contact-us" */
        './components/contact-us.vue'
    ));


const conversationGroup = Vue.component('conversation-group', () =>
    import (
        /* webpackChunkName: "conversation-group" */
        // './components/account/conversations/group/conversation-group.vue'
        './components/conversations/group/conversation-group.vue'
    ));

const formevent = Vue.component('form-event', () =>
    import (
        /* webpackChunkName: "form-event" */
        './components/account/form-event.vue'
    ));
const formeventdelete = Vue.component('form-event-delete', () =>
    import (
        /* webpackChunkName: "form-event-delete" */
        './components/account/form-event-delete.vue'
    ));
const formeventupdate = Vue.component('form-event-update', () =>
    import (
        /* webpackChunkName: "form-event-pdate" */
        './components/account/form-event-update.vue'
    ));

const formupdateagende = Vue.component('form-update-agenda', () =>
    import (
        /* webpackChunkName: "form-update-agenda" */
        './components/account/agenda/update.vue' 
    ));

const register = Vue.component('register', () =>
    import (
        /* webpackChunkName: "register" */
        './components/register.vue'
    ));
// const areacreate = Vue.component('area-create', require('./components/area/create.vue').default);
const areacreate = Vue.component('area-create', () =>
    import (
        /* webpackChunkName: "area-create" */
        './components/area/create.vue'
    ));


// const memberlist = Vue.component('member-list', require('./components/member-list.vue').default);
const memberlist = Vue.component('member-list', () =>
    import (
        /* webpackChunkName: "member-list" */
        './components/member-list.vue'
    ));

// const contact = Vue.component('contact', require('./components/contact.vue').default);
const contact = Vue.component('contact', () =>
    import (
        /* webpackChunkName: "contact" */
        './components/contact.vue'
    ));

// const news = Vue.component('news', require('./components/news.vue').default);
const news = Vue.component('news', () =>
    import (
        /* webpackChunkName: "news" */
        './components/news.vue'
    ));

// const project = Vue.component('project', require('./components/project.vue').default);
const project = Vue.component('project', () =>
    import (
        /* webpackChunkName: "project" */
        './components/project.vue'
    ));

// const opportunity = Vue.component('opportunity', require('./components/opportunity.vue').default);
const opportunity = Vue.component('opportunity', () =>
    import (
        /* webpackChunkName: "opportunity" */
        './components/opportunity.vue'
    ));

// const groupecreate = Vue.component('groupe-create', require('./components/groupe/create.vue').default);
const groupecreate = Vue.component('groupe-create', () =>
    import (
        /* webpackChunkName: "groupe-create" */
        './components/groupe/create.vue'
    ));

// const modalrole = Vue.component('modal-role', require('./components/groupe/modal-role.vue').default);
const modalrole = Vue.component('modal-role', () =>
    import (
        /* webpackChunkName: "modal-role" */
        './components/groupe/modal-role.vue'
    ));

// const listuser = Vue.component('list-user', require('./components/groupe/list-user.vue').default);
const listuser = Vue.component('list-user', () =>
    import (
        /* webpackChunkName: "list-user" */
        './components/groupe/list-user.vue'
    ));

// const invitations = Vue.component('invitations', require('./components/groupe/invitations.vue').default);
const invitations = Vue.component('invitations', () =>
    import (
        /* webpackChunkName: "invitations" */
        './components/groupe/invitations.vue'
    ));

// const requests = Vue.component('requests', require('./components/groupe/requests.vue').default);
const requests = Vue.component('requests', () =>
    import (
        /* webpackChunkName: "requests-groupe" */
        './components/groupe/requests.vue'
    ));

// const members = Vue.component('members', require('./components/groupe/members.vue').default);
const members = Vue.component('members', () =>
    import (
        /* webpackChunkName: "members-groupe" */
        './components/groupe/members.vue'
    ));

// const confirmaction = Vue.component('confirm-action', require('./components/confirm-action.vue').default);
const confirmaction = Vue.component('confirm-action', () =>
    import (
        /* webpackChunkName: "confirm-action" */
        './components/confirm-action.vue'
    ));

// const toast = Vue.component('toast', require('./components/toast.vue').default);
const toast = Vue.component('toast', () =>
    import (
        /* webpackChunkName: "toast-plugin" */
        './components/toast.vue'
    ));

// const invitmember = Vue.component('invit-member-list', require('./components/groupe/invit-member-list.vue').default);
const invitmember = Vue.component('invit-member-list', () =>
    import (
        /* webpackChunkName: "invit-member-list-groupe" */
        './components/groupe/invit-member-list.vue'
    ));

// const profile = Vue.component('account-profile', require('./components/account/profile.vue').default);
const profile = Vue.component('account-profile', () =>
    import (
        /* webpackChunkName: "account-profile" */
        './components/account/profile.vue'
    ));
const allContact =  Vue.component('all-members' , () => import(
    /* webpackChunkName: "all-members" */
    "./components/account/all-members.vue"
    ))

const listarea = Vue.component('list-area', () =>
    import (
        /* webpackChunkName: "list-area" */
        './components/area/list.vue'
    ));

// const profile = Vue.component('create-forum', require('./components/forum/create.vue').default);
const creatforum = Vue.component('create-forum', () =>
    import (
        /* webpackChunkName: "create-forum" */
        './components/forum/create.vue'
    ));


// const profile = Vue.component('create-forum', require('./components/forum/search.vue').default);
const searchsubject = Vue.component('search-subject', () =>
    import (
        /* webpackChunkName: "search-subject" */
        './components/forum/search.vue'
    ));
// const timezone = Vue.component('timezone', require('./components/timezone.vue').default);
const timezone = Vue.component('timezone', () =>
    import (
        /* webpackChunkName: "timezone" */
        './components/timezone.vue'
    ));

const filterproject = Vue.component('filter-project', () =>
    import (
        /* webpackChunkName: "filter-project" */
        './components/filter-project.vue'
    ));

const listproject = Vue.component('list-project', () =>
    import (
        /* webpackChunkName: "list-project" */
        './components/list-project'
    ));

const createproject = Vue.component('create-project', () =>
    import (
        /* webpackChunkName: "create-project" */
        './components/create-project.vue'
    ));

const udpateproject = Vue.component('update-project', () =>
    import (
        /* webpackChunkName: "update-project" */
        './components/update-project'
    ));

const deleteteproject = Vue.component('delete-project', () =>
    import (
        /* webpackChunkName: "delete-project" */
        './components/delete-project'
    ));

const storeudpate = Vue.component('store-update', () =>
    import (
        /* webpackChunkName: "store-update" */
        './components/store-update'
    ));


const createnews = Vue.component('account-create-news', () =>
    import (
        /* webpackChunkName: "account-create-news" */
        './components/account/news/create.vue' 
    ));

const updatenews = Vue.component('account-update-news', () =>
    import (
        /* webpackChunkName: "account-update-news" */
        './components/account/news/update.vue'
    ));

const updatenewsgroupe = Vue.component('account-update-news-groupe', () =>
    import (
        /* webpackChunkName: "account-update-news-groupe" */
        './components/account/news/update-publication.vue'
    ));


const choosenews = Vue.component('account-choose-news', () =>
    import (
        /* webpackChunkName: "account-choose-news" */
        './components/account/news/choose.vue'
    ));

const choosenewsdelete = Vue.component('account-choose-delete-news', () =>
    import (
        /* webpackChunkName: "account-choose-delete-news" */
        './components/account/news/choose-delete.vue'
    ));

const listnewsaccount = Vue.component('account-list-news', () =>
    import (
        /* webpackChunkName: "account-list-news" */
        './components/account/news/list.vue'
    ));

const updateprofile = Vue.component('update-profile', () =>
    import (
        /* webpackChunkName: "update-profile" */
        './components/update-profile.vue'
    ));

const relatedprojects = Vue.component('related-project', () =>
    import (
        /* webpackChunkName: "related-project" */
        './components/projects/related-project.vue'
    ));

const relatednews = Vue.component('related-news', () =>
    import (
        /* webpackChunkName: "related-news" */
        './components/newspaper/related.vue'
    ));

const relatednewsvideo = Vue.component('related-news-video', () =>
    import (
        /* webpackChunkName: "related-news-video" */
        './components/newspaper/related-video.vue'
    ));

const affiliateduser = Vue.component('account-affiliate-user', () =>
    import (
        /* webpackChunkName: "account-affiliate-user" */
        './components/account/affiliates/user.vue'
    ));

const affiliatedcommission = Vue.component('account-affiliate-commission', () =>
    import (
        /* webpackChunkName: "account-affiliate-commission" */
        './components/account/affiliates/commission.vue'
    ));

const gallerycreate = Vue.component('account-create-gallery', () =>
    import (
        /* webpackChunkName: "account-create-gallery" */
        './components/account/gallery/create.vue'
    ));

const galleryupdate = Vue.component('account-update-gallery', () =>
    import (
        /* webpackChunkName: "account-update-gallery" */
        './components/account/gallery/update.vue'
    ));

const gallerychoose = Vue.component('account-choose-albums', () =>
    import (
        /* webpackChunkName: "account-choose-albums" */
        './components/account/gallery/choose.vue'
    ));

const gallerychoosedelete = Vue.component('account-choose-delete-albums', () =>
    import (
        /* webpackChunkName: "account-choose-delete-albums" */
        './components/account/gallery/choose-delete.vue'
    ));

const interessed = Vue.component('interessed', () =>
    import (
        /* webpackChunkName: "interessed" */
        './components/interessed.vue'
    ));
const creategroupe = Vue.component('creategroupe', () =>
    import (
        /* webpackChunkName: "creategroupe" */
        './components/account/groupe/create-groupe.vue'
    ));
const updategroupe = Vue.component('updategroupe', () =>
    import (
        /* webpackChunkName: "updategroupe" */
        './components/account/groupe/update-groupe.vue'
    ));
const allrequest = Vue.component('allrequest', () =>
    import (
        /* webpackChunkName: "allrequest" */
        './components/account/groupe/allrequest.vue'
    ));
const allmembers = Vue.component('allmembers', () =>
    import (
        /* webpackChunkName: "allmembers" */
        './components/account/groupe/allmembers.vue'
    ));

const addmembers = Vue.component('addmembers', () =>
    import (
        /* webpackChunkName: "addmembers" */
        './components/account/groupe/addmembers.vue'
    ));

const allconvestation = Vue.component('list-conversation', () =>
    import (
        /* webpackChunkName: "list-conversation" */
        './components/conversations/list-conversation.vue'
    ));

const allinvitations = Vue.component('list-invitations', () =>
    import (
        /* webpackChunkName: "list-invitations" */
        './components/conversations/list-invitations.vue'
    ));

const formmessage = Vue.component('form-message', () =>
    import (
        /* webpackChunkName: "form-message" */
        './components/conversations/form-message.vue'
    ));

const listmessage = Vue.component('list-message', () =>
    import (
        /* webpackChunkName: "list-message" */
        './components/conversations/list-message.vue'
    ));

const gallerymessage = Vue.component('gallery-message', () =>
    import (
        /* webpackChunkName: "gallery-message" */
        './components/conversations/gallery.vue'
    ));

const business = Vue.component('business', () =>
    import (
        /* webpackChunkName: "business" */
        './components/business/business.vue'
    ));

const layoutcall = Vue.component('call-layout', () =>
    import (
        /* webpackChunkName: "layoutcall" */
        './components/conversations/call/layout.vue'
    ));

const joinusfooter = Vue.component('join-us-footer', () =>
    import (
        /* webpackChunkName: "join-us-footer" */
        './components/join-us-footer.vue'
    ));
const buttoncall = Vue.component('button-call', () =>
    import (
        /* webpackChunkName: "buttoncall" */
        './components/conversations/call/button-call.vue'
    ));

const listcall = Vue.component('list-call', () =>
    import (
        /* webpackChunkName: "list-call" */
        './components/conversations/call/list-call.vue'
    ));

const listcallmessage = Vue.component('list-message-call', () =>
    import (
        /* webpackChunkName: "list-message-call" */
        './components/conversations/call/list-message-call.vue'
    ));

const notifications = Vue.component('notification-account', () =>
    import (
        /* webpackChunkName: "notification-account" */
        './components/notifications/notification-account.vue'
    ));

const notificationsmessage = Vue.component('notification-message', () =>
    import (
        /* webpackChunkName: "notification-message" */
        './components/notifications/notification-message.vue'
    ));

const drapeauheader = Vue.component('drapeau-header', () =>
    import (
        /* webpackChunkName: "drapeau-header" */
        './components/drapeau-header.vue'
    ));

const listprojectwithfilter = Vue.component('list-index-project', () =>
    import (
        /* webpackChunkName: "list-index-project" */
        './components/projects/list-index.vue'
    ));

const writeProjects = Vue.component('write-projects', () =>
    import (
        /* webpackChunkName: "write-projects" */
        './components/projects/writeProjects.vue'
    ));

const callconversation = Vue.component('callconversation', () =>
    import (
        /* webpackChunkName: "callconversation" */
        './components/conversations/call/callconversation.vue'
    ));

const buttoncallconversation = Vue.component('button-call-contact', () =>
    import (
        /* webpackChunkName: "buttoncallconversation" */
        './components/conversations/call/button-call-contact.vue'
    ));

const buttoncallotherprofile = Vue.component('button-call-other-profile', () =>
    import (
        /* webpackChunkName: "buttoncallotherprofile" */
        './components/conversations/call/button-call-other-profile.vue'
    ));

const visioind = Vue.component('visio-index', () =>
    import (
        /* webpackChunkName: "buttoncallotherprofile" */
        './components/conversations/call/visio-index.vue'
    ));

const newwindowcall = Vue.component('new-window-call', () =>
    import (
        /* webpackChunkName: "newwindowcall" */
        './components/conversations/call/new-window-call.vue'
    ));

const buttoncallgroupe = Vue.component('button-call-groupe', () =>
import (
    /* webpackChunkName: "buttoncallgroupe" */
    './components/conversations/call/button-call-groupe.vue'
));

const imageprofile = Vue.component('image-profile', () =>
    import (
        /* webpackChunkName: "image-profile" */
        './components/account/updateProfil/image-profile.vue'
    ));

    
const app = new Vue({
    el: '#app'
});
